// Copyright Northcote Technology Ltd
var allConfirms = document.querySelectorAll('[data-confirm]')
Array.prototype.slice.call(allConfirms).forEach(function (confirm) {
  confirm.addEventListener('click', function (e) {
    var message = confirm.dataset.confirm
    if (!window.confirm(message)) {
      e.preventDefault()
    }
  })
})
