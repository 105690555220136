// Copyright Northcote Technology Ltd

import 'babel-polyfill'

//import 'src/init/stay_standalone'
import 'src/init/confirm'
import 'src/init/sentry'
import { setupIdb } from 'src/init/idb'

setupIdb()

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
